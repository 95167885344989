
import { defineComponent } from "vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "App",
  components: {
    Footer
  },
  setup() {
    const items = [
      {
        label: "Home",
        icon: "pi pi-fw pi-home",
        to: "/"
      },
      {
        label: "About",
        icon: "pi pi-fw pi-question",
        to: "/about"
      },
      {
        label: "Parts",
        icon: "pi pi-fw pi-cog",
        to: "/parts"
      }
    ];
    return { items };
  }
});
