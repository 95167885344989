
import { defineComponent } from "vue";
import OpticsTimeline from "@/components/OpticsTimeline.vue";

export default defineComponent({
  name: "Home",
  components: {
    OpticsTimeline
  }
});
