
import { defineComponent } from "vue";
import Button from "primevue/button";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
export default defineComponent({
  components: {
    Button,
    Toast
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      email: ""
    };
  },
  methods: {
    submitEmail() {
      console.log(`Submitting this email ${this.email}`);
      this.toast.add({
        severity: "success",
        summary: "Success",
        detail: "We received your email address thanks!",
        life: 3000
      });
      this.email = "";
    }
  }
});
