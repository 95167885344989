<template>
  <section class="position-relative">
    <div id="particles-js"></div>
    <div class="container">
      <div class="row  text-center">
        <div class="col">
          <h1>Learn More</h1>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4">
          <h5><span class="text-theme font-w-7">01.</span> Availability</h5>
          <p>
            Our top SFP Optics, and optical cables are typically shipped the
            same day.
          </p>
        </div>
        <div class="col-lg-4 col-md-4">
          <h5><span class="text-theme font-w-7">02.</span> Returns</h5>
          <p>
            Returns and replacement products are handled overnight, lifetime,
            free of charge.
          </p>
        </div>
        <div class="col-lg-4 col-md-4">
          <h5><span class="text-theme font-w-7">03.</span> Pricing</h5>
          <p>
            We charge the right price, not the rip-off price. Be careful the
            OEM’s do NOT own these patents. We buy from top-tier providers to
            ensure the bulk product is manufactured with quality first.
          </p>
        </div>
        <div class="col-lg-4 col-md-4">
          <h5>
            <span class="text-theme font-w-7">04.</span> Honesty & Transparency
          </h5>
          <p>
            We pride ourselves on honesty and transparency, no lies about
            pricing, or availability, or spiffs. We charge a fair margin over
            our costs. Price lies are common in the industry. Usually
            availability. We don’t lie.
          </p>
        </div>
        <div class="col-lg-4 col-md-4">
          <h5><span class="text-theme font-w-7">05.</span> Testing</h5>
          <p>
            All parts are pre-tested off country and post-tested after
            compatible coding takes place in country, which results in an almost
            zero failure rate. In Fact 99.87% reliability rate in the last four
            years. We can upon request ship direct from Asia, coded as well.
          </p>
        </div>
        <div class="col-lg-4 col-md-4">
          <h5><span class="text-theme font-w-7">06.</span> Compatibility</h5>
          <p>
            Leading OEM compatibility: HP, Juniper, Cisco, Arista, Brocade,
            3com, Finisar, Extreme, IBM, SMC and more.
          </p>
        </div>
        <div class="col-lg-4 col-md-4">
          <h5><span class="text-theme font-w-7">07.</span> Stock</h5>
          <p>
            We stock the top most common bulk parts. Cold spares are sent with
            larger orders to eliminate any possible down-time.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-lg-8 col-md-12">
          <div class="mb-6">
            <span class="badge badge-primary-soft p-2">
              <i class="la la-exclamation ic-3x rotation"></i>
            </span>
            <h2 class="mt-3">Compatible OEMs</h2>
            <p class="lead">
              Compliant with warranty specifications for all compatible OEMs.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <h5>Compatibility Info:</h5>
          <p>
            TruePriceIT™ Optics do not void OEM manufacturer’s warranties. By
            federal law manufacturers cannot require that consumers purchase
            only their upgrades in order to maintain the product warranty. These
            laws were designed to encourage competition by allowing consumers to
            buy products they might have preferred to purchase elsewhere and on
            different terms.
          </p>
          <p>
            To be clear, TruePriceIT™ Optics products interact with other
            networking products in such a manner that would NOT void warranties
            from other manufacturers. All of our parts are designed 100% OEM
            compatible. Out of the box compatible. You will find that this puts
            us in full compliance with the warranty agreements of these Major
            OEMS.
          </p>
          <h5 class="mt-5 mb-4">OEM Information:</h5>
          <div class="d-flex align-items-center mb-3">
            <div class="badge-primary-soft rounded p-1">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-check"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <p class="mb-0 ml-3">
              <span class="font-weight-bold">Cisco Warranty Information:</span>
              <a
                href="http://www.cisco.com/en/US/products/prod_warranties_listing.html"
                >http://www.cisco.com/en/US/products/prod_warranties_listing.html</a
              >
            </p>
          </div>
          <div class="d-flex align-items-center mb-3">
            <div class="badge-primary-soft rounded p-1">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-check"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <p class="mb-0 ml-3">
              <span class="font-weight-bold">HP Warranty Information:</span>
              <a href="http://h40059.www4.hp.com/warranty/support/tc.php"
                >http://h40059.www4.hp.com/warranty/support/tc.php</a
              >
            </p>
          </div>
          <div class="d-flex align-items-center mb-3">
            <div class="badge-primary-soft rounded p-1">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-check"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <p class="mb-0 ml-3">
              <span class="font-weight-bold"
                >Juniper Warranty Information:</span
              >
              <a href="http://www.juniper.net/support/warranty"
                >http://www.juniper.net/support/warranty</a
              >
            </p>
          </div>
          <div class="d-flex align-items-center">
            <div class="badge-primary-soft rounded p-1">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-check"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <p class="mb-0 ml-3">
              <span class="font-weight-bold"
                >Brocade Warranty Information:</span
              >
              <a
                href="http://www.brocade.com/services-support/warranties/index.page"
                >http://www.brocade.com/services-support/warranties/index.page</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
