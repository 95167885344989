
import { defineComponent } from "vue";
import { useToast } from "primevue/usetoast";

export default defineComponent({
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {};
  },
  methods: {
    submit() {
      this.toast.add({
        severity: "success",
        summary: "Success",
        detail: "Thanks for reaching out, redirecting to home",
        life: 3000
      });
      setTimeout(this.$router.push("/"), 1500);
    }
  }
});
